import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/layout/mainLayout.vue'
import Home from '../views/home/HomePage.vue'
import JoinAddress from '@/views/JoinAddress.vue'
import LoginPage from '@/views/loginFolder/LoginPage.vue'
import resetPassword from '@/views/loginFolder/resetPassword.vue'
import regUser from '@/views/loginFolder/regUser.vue'
import store from '@/store'
import Profile from '../views/ProfileView'
import CalculatePrice from '../views/PriceCalculate.vue'
import MapBranch from '../views/BranchMap.vue'
import TermsOfService from '../views/TermsOfService.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: MainLayout,
  redirect: '/',
  meta: {
    requiresAuth: false
  },
  children: [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false
    }
  }, {
    path: '/calculate',
    name: 'CalculatePrice',
    component: CalculatePrice,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/branches',
    name: 'BranchMap',
    component: MapBranch,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/termsOfService',
    name: 'TermsOfService',
    component: TermsOfService
  },
  {
    path: '/join',
    name: 'JoinAddress',
    component: JoinAddress,
    meta: {
      requiresAuth: false
    }
  }
  ]
},
{
  path: '/login',
  name: 'LoginPage',
  component: LoginPage,
  meta: {
    requiresAuth: false
  }
},
{
  path: '/resetPassword',
  name: 'resetPassword',
  component: resetPassword,
  meta: {
    requiresAuth: false
  }
},
{
  path: '/regUser',
  name: 'regUser',
  component: regUser,
  meta: {
    requiresAuth: false
  }
},
{
  path: '/profile',
  name: 'profile',
  component: Profile,
  meta: {
    requiresAuth: false
  }
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.meta.requiresAuth, 'too')

  if (to.meta.requiresAuth && !store.state.idToken) {
    next({
      name: 'Home'
    })
  } else if (!to.meta.requiresAuth) {
    next()
  } else {
    // console.log(asyncCall(), 'sda')
    if (store.state.user) {
      if (store.state.user.name != null) {
        next()
      } else {
        next({
          name: 'AuthError'
        })
      }
    } else {
      next()
    }
  }
})

export default router
