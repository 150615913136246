<template>
  <div>
    <el-dialog title="Нууц үг сэргээх" :visible.sync="this.$root.resetPasswordDialog" width="400px"
      :before-close="handleClose" center>
      <el-form class="text_center" ref="resetPasswordForm" :model="resetPasswordForm" :rules="rules">
        <el-form-item>
          <span class="content_justify_center p-b-5 no-wrap">Бүртгэлтэй утасны дугаар &nbsp;<p class="text_red">*</p></span>
          <el-input class="track_code_area_input" v-model="resetPasswordForm.phoneNumber"
            placeholder="Утасны дугаараа оруулна уу"></el-input>
        </el-form-item>
        <el-form-item v-if='isOtpReturned'>
          <span class="content_justify_center no-wrap">Баталгаажуулах код &nbsp;<p class="text_red"> *</p></span>
          <el-input class="track_code_area_input" v-model="resetPasswordForm.otp" type="text"
            placeholder="Баталгаажуулах кодоо оруулна уу"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-if='isOtpReturned'>
          <span class="content_justify_center no-wrap">Шинэ нууц үг &nbsp;<p class="text_red"> *</p></span>
          <el-input class="track_code_area_input" v-model="resetPasswordForm.newPassword" type="password"
            autocomplete="off" placeholder="Шинээр үүсгэх нууц үгээ оруулна уу.">
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" v-if='isOtpReturned'>
          <span class="content_justify_center no-wrap">Нууц үг /давтах/ &nbsp;<p class="text_red"> *</p></span>
          <el-input class="track_code_area_input" v-model="resetPasswordForm.confirmPassword" type="password"
            autocomplete="off" placeholder="Нууц үгээ ахин оруулна уу">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="button_background_color track_code_button" v-if="!isOtpReturned" @click="sendRequestOtp()">
            Нэг удаагийн нууц үг авах</el-button><br>
            <el-button class="button_background_color track_code_button" v-if="isOtpReturned" @click="resetPassword()">
              Нууц үг сэргээх
            </el-button><br>
          <el-button type="text" class="font_format text_color" @click="goToLoginDialog()">Буцах</el-button><br>
        </el-form-item>
      </el-form>
      <div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services'

export default {
  name: 'ResetPassword',
  data () {
    return {
      resetPasswordForm: {
        phoneNumber: '',
        otp: '',
        newPassword: null,
        confirmPassword: null
      },
      isOtpReturned: false,
      rules: {
        newPassword: [{
          required: true,
          message: 'Please enter your new password',
          trigger: 'blur'
        },
        {
          min: 3,
          message: 'Password must be at least 6 characters',
          trigger: 'blur'
        }
        ],
        confirmPassword: [{
          required: true,
          message: 'Please confirm your password',
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => {
            if (value !== this.resetPasswordForm.newPassword) {
              callback(new Error('Passwords do not match!'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
        ]
      }
    }
  },
  components: {},
  methods: {
    handleClose () {
      this.$root.resetPasswordDialog = false
    },
    sendRequestOtp () {
      services.sendRequestOtp(this.resetPasswordForm)
        .then((response) => {
          if (response.message) {
            this.$alert('Таны бүртгэлтэй утсанд нэг удаагийн нууц үг илгээгдлээ', 'Амжилттай', {
              confirmButtonText: 'Ойлголоо'
            })
            this.isOtpReturned = true
          }
        })
        .catch((error) => {
          this.$alert('Уучлаарай: ' + error, 'Алдаа', {
            confirmButtonText: 'Ойлголоо'
          })
        })
    },
    resetPassword () {
      services.resetPassword(this.resetPasswordForm)
        .then((response) => {
          if (response.message) {
            this.$alert('Таны бүртгэл амжилттай шинэчлэгдлээ', 'Амжилттай', {
              confirmButtonText: 'Баярлалаа',
              callback: action => {
                this.goToLoginDialog()
              }
            })
          }
        })
        .catch((error) => {
          this.$alert('Уучлаарай: ' + error, 'Алдаа', {
            confirmButtonText: 'Ойлголоо',
            callback: action => {
              this.goToLoginDialog()
            }
          })
        })
    },
    register () {
      this.$root.loginDialog = false
      this.$root.registerDialog = true
    },
    goToLoginDialog () {
      this.$root.loginDialog = true
    }
  }
}

</script>

<style>

</style>
