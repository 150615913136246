<template>
    <div>
        <el-row>
            <el-col justify="center">
                <h3 class="font_family m-b-20">ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</h3>
            </el-col>
            <el-col class="m-b-20" :offset="2" :span="20">
                <div class="font_family">
                    Хэрэглэгч нь skycargo.mn веб сайтад хандсан үеэс эхлэн Үйлчилгээний нөхцөлийг хүлээн зөвшөөрсөнд тооцно.
                </div>
            </el-col>
            <el-col :span="20" :offset="2">
                <el-collapse>
                    <el-collapse-item title="ЕРӨНХИЙ"><br>
                        <p><span class="number">1.1.</span> Энэхүү журмаар ТЭНГЭР КАРГО -н (цаашид САЙТ гэнэ) ачаа тээврийн үйлчилгээ, түүнтэй холбоотой үүсэх бүх төрлийн харилцааг зохицуулна.</p>
                        <p><span class="number">1.2.</span> САЙТ нь БНХАУ-д байрлалтай онлайн болон онлайн бус дэлгүүр, нийлүүлэгчээс хэрэглэгчийн сонгон захиалсан бараа бүтээгдэхүүнийг Эрээн дэх агуулахад хүлээн авч тээвэрлэх, нийлүүлэх үйлчилгээ үзүүлнэ.</p>
                        <p><span class="number">1.3.</span> Энэхүү журмын хэрэгжилтэд САЙТ болон хэрэглэгч хамтран хяналт тавина.</p>
                    </el-collapse-item>
                    <el-collapse-item title="ТАНЫ БҮРТГЭЛ"><br>
                        <p><span class="number">2.1.</span> Хэрэглэгч Тэнгэр карго үйлчилгээг ашиглахын тулд өөрийн байнга ашигладаг гар утасны дугаарыг ашиглан хэрэглэгчээр бүртгүүлж, бүртгэл амжилттай болсны дараа өөрийн хувийн мэдээллийг үнэн зөв оруулах шаардлагатай.</p>
                        <p><span class="number">2.2.</span> Та өөрийн бүртгэл, нууц үгийн аюулгүй байдлыг хангах үүрэгтэй бөгөөд таны бүртгэлээс хийгдсэн аливаа үйлдлийг хэрэглэгч өөрөө бүрэн хариуцна. </p>
                    </el-collapse-item>
                    <el-collapse-item title="ЗАХИАЛГА"><br>
                        <span class="text_justify">
                            Хэрэглэгч Тэнгэр карго-н Эрээн дэх агуулахын хаягийг БНХАУ-н бараа захиалгын аппликэйшний хүргэлтийн хаяг хэсэгт холбож тохируулах ба Эрээн агуулахын хаяг зөв холбогдсон эсэхийг Тэнгэр карго фэйсбүүк хуудасны мессеж хэсгээр явуулж, шалгуулна. Эрээн агуулахын хаягаа буруу холбосон, зохих журмаар шалгуулаагүйгээс үүсэх хариуцлагыг Тэнгэр карго хүлээхгүй.<br>
                            Ачаа нь түүвэр ачаа болон овортой ачаа гэсэн төрөлд хуваагдана. Түүвэр ачаанд 1 м.куб хүртэлх ачаа, овортой ачаанд 1 м.куб-с дээш ачаа хамаарна. Хэрэглэгч түүвэр ачааны захиалга хийхдээ Тэнгэр карго-нд урьдчилан мэдэгдэх шаардлагагүй. Овортой ачааны захиалга хийх хэрэглэгч tengerb2b@gmail.com хаягаар холбогдож ачаа тээврийн урьдчилсан үнийн санал авах шаардлагатай.<br>
                            <p><span class="number">3.1.</span>  САЙТ нь хэрэглэгчийн БНХАУ-д үйл ажиллагаа эрхэлдэг онлайн болон онлайн бус дэлгүүрүүд, нийлүүлэгчдээс сонгон захиалсан бараа бүтээгдэхүүнийг (цаашид ачаа гэх) Эрээн дэх агуулахад хүлээн авч, тээвэрлэх үйлчилгээ үзүүлнэ.</p>
                            <p><span class="number">3.2.</span> Ачаа тээврийн үйлчилгээ нь хэрэглэгч захиалга өгснөөс эхлэн тээвэрлэлт, барааг хүлээлгэн өгөх хүртэлх бүх үйл явцыг хамрах ба www.skycargo.mn системээр удирдагдана.</p>
                        </span>
                    </el-collapse-item>
                    <el-collapse-item title="ТРАК КОД БҮРТГЭХ, АЧААНЫ ТӨЛӨВ ШАЛГАХ"><br>
                        <p><span class="number">1.</span> Хэрэглэгч захиалсан ачааныхаа БНХАУ доторх хүргэлтийн төлөвийг захиалсан бараа захиалгын аппликэйшнээс болон бараа нийлүүлсэн талаас хянана.</p>
                        <p><span class="number">2.</span> Хэрэглэгчийн захиалсан ачаа БНХАУ дотор хүргэлтэнд гарах үед ачааны трак код үүсэх ба хэрэглэгч захиалсан ачааныхаа трак кодыг сайтын трак код бүртгэх хэсэгт бүртгэх үүрэгтэй.</p>
                        <p><span class="number">3.</span> Хэрэглэгч ачааны дараах төлөвүүдийг САЙТ-ын миний бүртгэл хэсгээс хянах боломжтой. Үүнд:</p>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хэрэглэгч бүртгэсэн<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Эрээн дах агуулахаас гарсан<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Монгол дах салбар хүлээн авсан<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хэрэглэгч хүлээн авсан<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хэрэглэгч хүргэлтээр авна<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хэрэглэгч салбараас очиж авна<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хэрэглэгч салбар дээр хадгалуулна<br>
                    </el-collapse-item>
                    <el-collapse-item title="ТЭЭВЭРЛЭЛТ"><br>
                        <p><span class="number">5.1.</span> Тээвэрлэлт нь БНХАУ дахь хүргэлт, БНХАУ-аас Монгол хүртэлх тээвэрлэлт гэсэн 2 үндсэн үе шатаас бүрдэнэ.</p>
                        <p><span class="number">5.2.</span> БНХАУ дахь хүргэлт нь тухайн дэлгүүр, нийлүүлэгчийн ашиглаж буй дотоодын хүргэлтийн үйлчилгээгээр нийлүүлэгч хүргэлтийг бүрэн хариуцна.</p>
                        <p><span class="number">5.3.</span> Бараа бүтээгдэхүүн САЙТ-н БНХАУ дахь агуулахад хүргэгдэн, Монгол улсруу ачигдсанаас хойш хэвийн ажиллагаатай үед энгийн тээвэр (Тэнгэр карго) дунджаар 4-10 хоног, шуурхай тээвэр (Тэнгэр экспресс) дунджаар 2-3 хоног дотор хэрэглэгчид хүрнэ. Бүх нийтийн баяр ёслол,гэнэтийн давагдашгүй хүчин үйл үүссэн тохиолдолд тодорхой болон тодорхойгүй хугацаагаар сунгагдаж болно. Гэнэтийн болон давагдашгүй хүчин зүйл гэдэгт гал түймэр, газар хөдлөлт, салхи шуурга, аянга цахилгаан, үер усны гамшиг, нийтийг хамарсан үймээн самуун, эмх замбараагүй байдал, ажил хаялт, эрчим хүчний тасалдал, эсэргүүцлийн хөдөлгөөн, гоц халдварт өвчин, дайн, террорист халдлага, төрийн эрх бүхий байгууллагын шийдвэр, хорио, цээр зэрэг талуудын хүсэл зоригоос үл хамаарах шалтгаанууд багтана.</p>
                    </el-collapse-item>
                    <el-collapse-item title="ҮНЭ, ТӨЛБӨР"><br>
                        <p><span class="number">6.1.</span> Ачаа тээврийн үнэд БНХАУ-с Монгол руу тээвэрлэсэн тээврийн зардал багтах бөгөөд ачаа Улаанбаатарт ирсэний дараа сайтын үнэ тооцоолох систем бодит жин болон овор хэмжээгээр нь автоматаар тооцоолон гаргана. Ингэхдээ дараах үнийн тарифыг баримтлан тооцоолно. Үүнд:</p><br>
                        <p><span class="number"><u>6.1.1.</u></span><u>Энгийн тээвэр (Тэнгэр карго) – 1кг 2,500₮</u></p><br>
                        <table border="1" cellspacing="0" cellpadding="5">
                            <thead>
                                <tr>
                                <th>Ачааны төрөл</th>
                                <th>Хэмжээ</th>
                                <th>Үнэ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td rowspan="4">Жижиг ууттай энгийн ачаа</td>
                                <td>0-150гр</td>
                                <td>1,000</td>
                                </tr>
                                <tr>
                                <td>151-1000гр</td>
                                <td>2,500</td>
                                </tr>
                                <tr>
                                <td>1001-2000гр</td>
                                <td>5,000</td>
                                </tr>
                                <tr>
                                <td>2001-3000гр</td>
                                <td>7,500</td>
                                </tr>
                                <tr>
                                <td rowspan="8">Жижиг ууттай хүнд ачаа</td>
                                <td>3001-4000гр</td>
                                <td>10,000</td>
                                </tr>
                                <tr>
                                <td>4001-5000гр</td>
                                <td>11,000</td>
                                </tr>
                                <tr>
                                <td>5001-6000гр</td>
                                <td>12,000</td>
                                </tr>
                                <tr>
                                <td>6001-7000гр</td>
                                <td>13,000</td>
                                </tr>
                                <tr>
                                <td>7001-8000гр</td>
                                <td>14,000</td>
                                </tr>
                                <tr>
                                <td>8001-9000гр</td>
                                <td>15,000</td>
                                </tr>
                                <tr>
                                <td>9001-10000гр</td>
                                <td>16,000</td>
                                </tr>
                                <tr>
                                <td>10001-11000гр</td>
                                <td>17,000</td>
                                </tr>
                                <tr>
                                <td rowspan="4">Жижиг хайрцагтай энгийн ачаа</td>
                                <td>0-150гр</td>
                                <td>1,500</td>
                                </tr>
                                <tr>
                                <td>151-1000гр</td>
                                <td>3,000</td>
                                </tr>
                                <tr>
                                <td>1001-2000гр</td>
                                <td>6,000</td>
                                </tr>
                                <tr>
                                <td>2001-3000гр</td>
                                <td>9,000</td>
                                </tr>
                                <tr>
                                <td rowspan="8">Жижиг хайрцагтай хүнд ачаа</td>
                                <td>3001-4000гр</td>
                                <td>12,000</td>
                                </tr>
                                <tr>
                                <td>4001-5000гр</td>
                                <td>13,000</td>
                                </tr>
                                <tr>
                                <td>5001-6000гр</td>
                                <td>14,000</td>
                                </tr>
                                <tr>
                                <td>6001-7000гр</td>
                                <td>15,000</td>
                                </tr>
                                <tr>
                                <td>7001-8000гр</td>
                                <td>16,000</td>
                                </tr>
                                <tr>
                                <td>8001-9000гр</td>
                                <td>17,000</td>
                                </tr>
                                <tr>
                                <td>9001-10000гр</td>
                                <td>18,000</td>
                                </tr>
                            </tbody>
                        </table><br>
                        Энгийн том ачаа – 1 м.куб 650юань (Юанийн ханш тухайн өдрийн ханшаар бодогдоно)<br><br>
                        Хүнд том ачаа – Эзэлхүүн, жинг харьцуулан тооцоолно.<br><br>
                        Овортой том ачааны хувьд: <br>
                        Хэрэглэгч 1 м.куб-с дээш хэмжээтэй ачаа захиалах бол tengerb2b@gmail.com хаягаар ачааны урт, өргөн, өндөр, жин, үзүүлэлтийн мэдээллийг дэлгэрэнгүй явуулж, ачаа тээврийн урьдчилсан үнийн санал авна.<br><br>
                        <p><span class="number"><u>6.1.2.</u></span><u>Шуурхай тээвэр (Тэнгэр экспресс) – 1кг 3,999₮</u></p><br>
                        <table border="1" cellspacing="0" cellpadding="5">
                            <thead>
                                <tr>
                                <th>Ачааны төрөл</th>
                                <th>Хэмжээ</th>
                                <th>Үнэ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td rowspan="4">Жижиг ууттай, хайрцагтай энгийн ачаа</td>
                                <td>0-150гр</td>
                                <td>2,000</td>
                                </tr>
                                <tr>
                                <td>151-1000гр</td>
                                <td>4,000</td>
                                </tr>
                                <tr>
                                <td>1001-2000гр</td>
                                <td>8,000</td>
                                </tr>
                                <tr>
                                <td>2001-3000гр</td>
                                <td>12,000</td>
                                </tr>
                                <tr>
                                <td rowspan="7">Жижиг ууттай, хайрцагтай хүнд ачаа</td>
                                <td>3001-4000гр</td>
                                <td>16,000</td>
                                </tr>
                                <tr>
                                <td>4001-5000гр</td>
                                <td>18,000</td>
                                </tr>
                                <tr>
                                <td>5001-6000гр</td>
                                <td>20,000</td>
                                </tr>
                                <tr>
                                <td>6001-7000гр</td>
                                <td>22,000</td>
                                </tr>
                                <tr>
                                <td>7001-8000гр</td>
                                <td>24,000</td>
                                </tr>
                                <tr>
                                <td>8001-9000гр</td>
                                <td>26,000</td>
                                </tr>
                                <tr>
                                <td>9001-10000гр</td>
                                <td>28,000</td>
                                </tr>
                            </tbody>
                        </table><br>
                        <p style="color: red;">Шуурхай тээврээр том ачаа хүлээн авах боломжгүй.</p><br>
                        <p><span class="number">6.2.</span> Хэрэглэгчийн захиалсан ачаанд Монгол улсын хуулийн дагуу гаалийн болон бусад татвар ноогдуулах тохиолдолд ачаа тээврийн үнэ дээр нэмж тооцоолно.</p>
                        <p><span class="number">6.3.</span> Хэрэглэгч ачаа тээврийн үнийг 100 хувь төлснөөр хэрэглэгчид ачааг хүлээлгэн өгнө.</p>
                        <p><span class="number">6.4.</span> Ачаа тээврийн үнэ автоматаар MNT (төгрөг)-р бодогдох бөгөөд систем хөрвүүлэхдээ тухайн өдрийн CNY зарах ханшаар тооцон хөрвүүлнэ.</p>
                        <p><span class="number">6.5.</span> Төлбөрийг веб сайт дээрх төлбөр төлөх төгрөгийн дансаар болон салбарт бэлэн мөнгөөр, картаар төлнө.</p>
                    </el-collapse-item>
                    <el-collapse-item title="БАРАА ОЛГОЛТ"><br>
                        <p><span class="number">7.1.</span> Хэрэглэгч Монголд ирсэн ачаагаа Тэнгэр карго салбаруудаас өөрийн биеэр ирж авах боломжтой. Салбаруудын хаяг байршил, цагийн хуваарь, холбогдох утасны дугаарын мэдээллийг skycargo.mn вэб сайтад байршуулна.</p>
                        <p><span class="number">7.2.</span> Тээврийн зардлыг хэрэглэгч 100% төлсөн тохиолдолд барааг олгоно.</p>
                        <p><span class="number">7.3.</span> Захиалгыг хэрэглэгчид олгох үед хэрэглэгч бараа бүтээгдэхүүнийг сайтар шалган, эвдрэл гэмтлийг нягтлан үзсэний үндсэн дээр хүлээн авна. Барааг хэрэглэгч хүлээн авснаас хойш гарсан эвдрэл гэмтэл болон бусад асуудлуудад САЙТ хариуцлага хүлээхгүй.</p>
                    </el-collapse-item>
                    <el-collapse-item title="ХҮРГЭЛТ"><br>
                        <p><span class="number">8.1.</span> САЙТ хэрэглэгчийн хүсэлд нийцүүлэн нийслэл хот, хөдөө орон нутгийн унаанд захиалгын бараа бүтээгдэхүүнийг хүргэх үйлчилгээ үзүүлнэ.</p>
                        <p><span class="number">8.2.</span> Хүргэлтийн үйлчилгээ авах эсэхийг хэрэглэгч ачаа Монголд ирсэний дараа <a href="https://forms.gle/Urh1ZJ6XWBZGmvhQ6" style="color: blue;">https://forms.gle/Urh1ZJ6XWBZGmvhQ6</a> линкээр мэдэгдэнэ.</p>
                        <p><span class="number">8.3.</span> Тухайн АЖЛЫН ӨДРИЙН 15:00 цагаас өмнө ирсэн хүргэлтийн захиалгын ачаа тээврийн болон хүргэлтийн төлбөр тооцоо тухайн ажлын өдрийн 16:00 цаг хүртэл хэрэглэгчийн утасны дугаарт мессежээр илгээгдэнэ. </p>
                        <p><span class="number">8.4.</span> Хүргэлтийн захиалгын төлбөр тооцоо тухайн ажлын өдрийн 17:00 цагаас өмнө 100% төлөгдөж, энэ тухай мессеж илгээсэн утасны дугаарт эргэн мессежээр мэдэгдсэн тохиолдолд ачааг хүргэлтийн компанид хүлээлгэн өгнө.</p>
                        <p><span class="number">8.5.</span> Ачааг хүргэлтийн компанид хүлээлгэн өгснөөс хойш хүргэлтийн төлөвийг 7200-7210 утасны дугаараар лавлах боломжтой ба 24-48 цагийн дотор хэрэглэгчийн мэдэгдсэн хаягаар хүргэгдэнэ.</p>
                        <p><span class="number">8.6.</span> Хүргүүлэх ачааны жин, овор хэмжээнээс хамааран хүргэлтийн үнэ тогтоогдож, ачаа тээврийн төлбөр дээр нэмж тооцогдоно. Үүнд:</p><br>
                        <table border="1" cellspacing="0" cellpadding="5">
                            <thead>
                                <tr>
                                <th>Овор хэмжээ</th>
                                <th>Жин</th>
                                <th>Хүргэлтийн үнэ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>60смX60смX60см-с доош</td>
                                <td>10кг-с доош</td>
                                <td>7,000₮</td>
                                </tr>
                                <tr>
                                <td>60смХ60смХ60см-с 80смХ80смХ80см</td>
                                <td>10кг-с 15кг</td>
                                <td>12,000₮</td>
                                </tr>
                                <tr>
                                <td>80смХ80смХ80см-с 1мХ1мХ1м</td>
                                <td>15кг-с 20кг</td>
                                <td>20,000₮</td>
                                </tr>
                                <tr>
                                <td>1мХ1мХ1м-с дээш</td>
                                <td>20кг-с дээш</td>
                                <td>30,000₮</td>
                                </tr>
                            </tbody>
                        </table><br>
                        <p><span class="number">8.7.</span> Улаанбаатар хот доторх хүргэлтийн бүсээс хэтэрсэн тохиолдолд дээрх тариф дээр нэмж тооцогдох боломжтой. Хүргэлтийн бүс Тахилт, Баянхошуу, 7 буудал, Шар хад, Амгалан, Зайсан, Буянт ухаа 2 бүсээр хязгаарлагдана.</p>
                        <p><span class="number">8.8.</span> Орон нутгийн унаанд тавих төлбөр нь хот доторх тээврийн үнэтэй ижил байна. Орон нутгийн унааны ачаа тээврийн төлбөрийг хэрэглэгч бүрэн хариуцна.</p>
                    </el-collapse-item>
                    <el-collapse-item title="БАРАА ХАДГАЛАЛТ"><br>
                        <p><span class="number">9.1.</span> Ачаа Монголд ирсэнээс хойш 21 хоног хүртэл Тэнгэр карго салбарт үнэгүй хадгална.</p>
                        <p><span class="number">9.2.</span> Ачаа Монголд ирсэнээс хойш 21-с дээш хоног хадгалагдах тохиолдолд дараах байдлаар алданги тооцно. Үүнд:</p>
                        <p><span class="number">&nbsp;&nbsp;<u>9.2.1.</u></span> &nbsp;&nbsp;Бүртгэлтэй ирсэн бараа: Тухайн бараа нь хэрэглэгчийн бүртгэлтэй холбогдох утасны дугаар луу бараагаа ирж авах талаар сануулсан, алданги тооцох болон актлах талаар мэдээлэл өгсөн боловч ирж аваагүй бол тухайн барааны тээврийн үнийн дүнгийн 10 хувиар хоног тутамд алданги тооцох болно.</p>
                        <p><span class="number">&nbsp;&nbsp;<u>9.2.2.</u></span> &nbsp;&nbsp;Бүртгэлгүй ирсэн бараа: Бүртгэлтэй хэрэглэгчийн мэдээлэл огт байхгүй, ямар ч холбоо барих боломжгүйгээс үүдэн хадагалах хугацаа хэтрүүлсэн бараа бол нийт тээврийн үнийн дүнгийн 1 хувиар хоног тутамд алданги тооцох болно.</p>
                    </el-collapse-item>
                    <el-collapse-item title="ТРАК КОДООР БҮРТГЭГДСЭН АЧАА"><br>
                        <p><span class="number">10.1.</span> Ачаан дээр хэрэглэгчийн утасны дугаар хэвлэгдээгүй, хэрэглэгч захиалсан ачааныхаа трак кодыг skycargo.mn сайтад бүртгээгүй ачааг системд зөвхөн ачааны трак кодоор бүртгэнэ. Энэ төрлийн ачааг сайтнаас трак кодоор хайж, шалгах боломжтой.</p>
                        <p><span class="number">10.2.</span> Сайтад зөвхөн трак кодоор бүртгэгдсэн ачааг хэрэглэгчид олгохдоо бараа захиалгын аппликэйшнээс захиалсан хуудас болон ачааны трак кодыг тулгаж, шалгасны үндсэн дээр хүлээлгэн өгнө. </p>
                    </el-collapse-item>
                    <el-collapse-item title="БҮРТГЭЛД ОРООГҮЙ АЧАА"><br>
                        <p><span class="number">11.1.</span> Хэрэглэгчийн захиалсан ачаа Эрээн агуулахад очсоноос хойш энгийн тээврийн (Тэнгэр карго) хувьд 10 хоногоос дээш, шуурхай тээврийн (Тэнгэр экспресс) хувьд 5 хоногоос дээш Монголд ирээгүй тохиолдолд хэрэглэгч тус ачааг <a href="https://forms.gle/XtALb3FhcZcTy2Mt8" style="color:blue">https://forms.gle/XtALb3FhcZcTy2Mt8</a> линкээр илгээж, шалгуулна.</p>
                        <p><span class="number">11.2.</span> Бүртгэлд ороогүй ачааг Эрээн салбарт хүлээн авсан эсэх, ямар шалтгааны улмаас хугацаандаа ирээгүй талаар шалгаж, хэрэглэгч шалгуулснаас хойш 7 хоногийн дотор эргэн хариу өгнө. </p>
                        <p><span class="number">11.3.</span> Бүртгэлд ороогүй ачааг шалгуулснаас хойш 10 хоногийн дотор шийдвэрлэгдээгүй тохиолдолд хэрэглэгчээс ачаа захиалсан захиалгын хуудсыг авч, ачааны үнийг шилжүүлнэ. Энэ тохиолдолд тухайн өдрийн CNY (юань) ханшаар хөрвүүлэн MNT (төгрөг)-өөр төлбөр тооцоог хийнэ.</p>
                    </el-collapse-item>
                    <el-collapse-item title="ХОРИОТОЙ БОЛОН ТУСГАЙ ЗӨВШӨӨРӨЛТЭЙ БАРАА"><br>
                        <p><span class="number">12.1.</span> ‼‼ Каргогоор тээвэрлэхэд тохиромжгүй доорх төрлийн ачааг Эрээн агуулахад хүлээж авахгүй болохыг анхаарна уу ‼‼ Үүнд:</p>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Амархан хагарч эвдрэх зүйлс (шил, шилэн нүүртэй эдлэл, шилэн савалгаатай гоо сайхны бүтээгдэхүүн, шаазан, толь, ваар, хуванцар, чулуун тавцан г.м)<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Доргилтонд гэмтэх магадлалтай технологийн бүтээгдэхүүн (компьютер, таблет, түүний эд анги, дэлгэц, телевизор г.м)<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хэт том овортой зүйлс (буйдан, тавилга, үйлдвэрлэлийн тоног төхөөрөмж г.м)<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хэт өндөр үнэтэй, жижиг овортой зүйлс (Үнэт эдлэл, чип, гар утас, технологийн бүтээгдэхүүн г.м)<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Бүх төрлийн хүнсний бүтээгдэхүүн (жимс жимсгэнэ, чихэр, хадгалалтын горим алдагдах зүйлс г.м)<br>
                        <p><span class="number">12.2.</span> Гаалиар тээвэрлэхийг хориглодог бүтээгдэхүүний жагсаалтад орсон бүх төрлийн бараа бүтээгдэхүүниг тээвэрлэх боломжгүй. Үүнд:</p>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Согтууруулах ундаа<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Тамхи<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Стратегийн хүнс<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Жижиглэн савалгаатай, 25%-с дээш концентрацитай, хүнсний зориулалттай цууны хүчил<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Зохих зөвшөөрөлгүй хүний болон малын эм, эмийн бүтээгдэхүүн, эмнэлгийн хэрэгсэл<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Тэсэрч дэлбэрэх бодис, тэсэлгээний хэрэгсэл, түүхий эд<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Мод, сөөгний тарьц, суулгац<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Мал, амьтан<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Химийн хорт бодис<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Мансууруулах болон сэтгэцэд нөлөөт бодис<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Аюултай хог хаягдал<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Асбест агуулсан тоормозны накладка<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Цөмийн бодис<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Галт зэвсэг, сум, галт зэвсэгтэй адилтгах хэрэгсэл<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Бүх төрлийн шатахуун<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Пиротехникийн хэрэгсэл<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp; Хууль тогтоомжоор хориглосон бусад зүйл<br>
                        <p><span class="number">12.3.</span> Тусгай зөвшөөрлийн лиценз шаарддаг бараа бүтээгдэхүүн захиалах тохиолдолд хэрэглэгч тухайн бүтээгдэхүүний тусгай зөвшөөрлийн лицензтэй бол САЙТ-аар Монгол улсын гааль хүртэлх гүйцэтгэлийг гүйцэтгүүлж болно.</p>
                    </el-collapse-item>
                    <el-collapse-item title="ҮЙЛЧИЛГЭЭ ҮЗҮҮЛЭХЭЭС ТАТГАЛЗАХ НӨХЦӨЛ"><br>
                        Хэрэглэгчийн зүгээс дараах нөхцөл байдлыг үүсгэсэн тохиолдолд САЙТ хэрэглэгчид үйлчилгээ үзүүлэхээс татгалзах, хэрэглэгчийн нэвтрэх эрхийг хязгаарлах эрхтэй. Үүнд:<br>
                        <p><span class="number">13.1.</span> Хэрэглэгч үндэслэлгүй гомдол 3 ба түүнээс дээш удаа гаргаж, үйл ажиллагаанд саад учруулсан</p>
                        <p><span class="number">13.2.</span> Хэрэглэгч харилцааны доголдол гаргасан, ажилтныг илт доромжилсон, ажилд саад болсон</p>
                    </el-collapse-item>
                    <el-collapse-item title="ХАРИУЦЛАГА"><br>
                        <p><span class="number">14.1.</span> Хэрэглэгчийн сонгосон захиалгын бүтээгдэхүүнүүд нь САЙТ-тай хамааралгүй БНХАУ-ын дэлгүүр, нийлүүлэгчийн бараа бүтээгдэхүүн тул тэдгээрийн чанар бусад үзүүлэлтүүдэд САЙТ хариуцлага хүлээхгүй.</p>
                        <p><span class="number">14.2.</span> Дэлгүүрийн найдвартай байдал, бараа бүтээгдэхүүний сонголт, чанартай холбоотой асуудлыг хэрэглэгч бүрэн хариуцна.</p>
                    </el-collapse-item>
                </el-collapse>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
  name: 'TermsOfService'
}
</script>

<style>
    .font_family {
        font-family: 'Geologica', sans-serif;
        text-align: center;
    }
    .number {
        display: inline-block; /* Дугаарыг бие даан байрлуулах */
        width: 30px; /* Дугаарын өргөнийг тохируулах */
        margin-right: 5px; /* Дугаарын ба текстийн хооронд зай нэмэх */
    }
    .text_justify {
        align-items: justify !important;
    }
    .el-collapse-item__header {
        padding: 5px 10px !important;
        line-height: 1.2 !important;
    }
    .el-collapse-item__header {
        /* background-color: #0c265c !important; */
        color: #000000 !important;
        padding: 10px;
        border-radius: 5px;
    }
    .el-collapse-item__header:hover {
        background-color: #0c265c !important;
        color: #ffffff !important;
    }
</style>
