<template>
    <div id="app" class="home_page">
      <HeaderComponent />
          <TermsOfServiceComponent/>
        <FooterComponents class="m-t-200"/>
    </div>
  </template>
<script>
import TermsOfServiceComponent from '@/components/TermsOfService.vue'
import FooterComponents from '@/components/FooterComponents.vue'
import HeaderComponent from '@/components/HeaderComponent'
export default {
  name: 'HomePage',
  data () {
    return {
    }
  },
  components: {
    HeaderComponent,
    TermsOfServiceComponent,
    FooterComponents
  }
}
</script>

<style>
</style>
