<template>
  <div>
    <div>
      <el-row>
        <el-col>
          <!-- <el-card v-for="item in trackCodesList" :key="item.id" :value="item.id" class="m-b-10">
            <h3>Трак код: {{ item.track_code }}</h3><br>
            <span>Үүсгэсэн огноо: {{ item.createdAt.split('T')[0] }}</span><br><br>
            Төлөв: <el-tag size="small">{{ returnStatusValue(item.Progress[0].progress_code) }}</el-tag><br><br>
            <el-button @click="viewOrderDetail(item)" class="cursor button_background_color border_radius_10">Дэлгэрэнгүй</el-button>
          </el-card> -->
          <el-tabs>
            <el-tab-pane>
              <span slot="label" @click="searchTrackCodeByPhoneNumberOrderBy(100)">
                Хэрэглэгч бүртгэсэн
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(110)">
                Эрээн дах агуулах хүлээн авсан
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(120)">
                Монгол дах салбар хүлээн авсан
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(130)">
                Хэрэглэгч хүргэлтээр авна
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(140)">
                Хэрэглэгч салбараас очиж авна
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(150)">
                Хэрэглэгч салбар дээр хадгалуулна
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(160)">
                Хүргэлтийн ажилтанд хуваарилсан
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(170)">
                Ачаа салбар дээр үлдсэн
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" />
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"  @click="searchTrackCodeByPhoneNumberOrderBy(200)">
                Хэрэглэгч хүлээн авсан
              </span>
              <div>
                <OrderListTab :orderListProp="orderList" :emptyValueProp="emptyValue"/>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import OrderListTab from './Dialog/orderListTab.vue'
import services from '@/helpers/services'
import {
  mapGetters
} from 'vuex'

export default {
  data () {
    return {
      selectOrder: {},
      orderList: [],
      emptyValue: {}
    }
  },
  props: {
    trackCodesList: Array,
    trackCodeDetailProp: Object
  },
  components: {
    OrderListTab
  },
  computed: {
    ...mapGetters([
      'userDetailGetter'
    ])
  },
  created () {
    this.searchTrackCodeByPhoneNumberOrderBy(100)
  },
  methods: {
    searchTrackCodeByPhoneNumberOrderBy (data) {
      try {
        this.$root.fullscreenLoading = true
        const phoneNumber = this.userDetailGetter.phone1
        if (!phoneNumber) {
          this.$root.fullscreenLoading = false
          return
        }
        services.searchTrackCodeByPhoneNumberOrderBy(phoneNumber, data)
          .then((response) => {
            if (response.type === 'success') {
              this.orderList = response.data
            } else {
              this.emptyValue = 'Хоосон'
            }
            this.$root.fullscreenLoading = false
          })
          .catch((error) => {
            console.error('Алдаа:', error.message)
            this.emptyValue = error.message
            this.$root.fullscreenLoading = false
          })
      } catch (error) {
        console.error('Алдаа:', error.message)
      }
    }

  }
}
</script>

<style>
.m-b-10 {
  margin-bottom: 10px;
}
</style>
