<template>
  <div class="service_area m-t-100">
    <div class="service_title_zone">
    <el-row>
      <el-col :span="24">
        <h3>Таны хөтөч - Тэнгэр карго</h3>
      </el-col>
      <el-col :span="24">
        <hr>
      </el-col>
    </el-row>
    </div>
    <el-row :gutter="20" class="service_row p-l-250" >
      <el-col :xs="22" :sm="10" :md="12" :lg="4" :xl="4">
        <div @click="goToAddressJoin('/join')">
          <el-card shadow="hover" class="service_item">
            <i class="fa-solid fa-location-dot p-b-10"></i>
            <br />
            <span class="service_item_desc"> Хаяг холбох заавар</span>
          </el-card>
        </div>
      </el-col>
      <el-col :xs="22" :sm="10" :md="12" :lg="4" :xl="4">
        <a href="https://www.facebook.com/profile.php?id=100070131997091" target="_blank">
          <el-card shadow="hover" class="service_item">
            <i class="fa-solid fa-graduation-cap p-b-10"></i>
            <br />
            <span class="service_item_desc">Үнэгүй сургалтууд</span>
          </el-card>
        </a>
      </el-col>
      <el-col :xs="22" :sm="10" :md="12" :lg="4" :xl="4">
        <div @click="goToCalculate('/calculate')">
          <el-card shadow="hover" class="service_item">
            <i class="fa-solid fa-money-bills p-b-10"></i>
            <br />
            <span class="service_item_desc">Үнийн мэдээлэл</span>
          </el-card>
        </div>
      </el-col>
      <el-col :xs="22" :sm="10" :md="12" :lg="4" :xl="4">
        <div @click="goToAddress('/branches')">
          <el-card shadow="hover" class="service_item">
            <i class="fa-solid fa-tree-city p-b-10"></i>
            <br />
            <span class="service_item_desc"> Салбаруудын мэдээлэл</span>
          </el-card>
        </div>
      </el-col>
      <el-col :xs="22" :sm="10" :md="12" :lg="4" :xl="4">
        <div>
          <el-card shadow="hover" class="service_item">
            <i class="fa-solid fa-file-signature p-b-10"></i>
            <br />
            <span class="service_item_desc">Үйлчилгээний нөхцөл</span>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data () {
    return {
    }
  },
  methods: {
    goToAddressJoin (url) {
      if (this.$route.path !== url) {
        this.$router.push('/join')
      }
    },
    goToCalculate (url) {
      if (this.$route.path !== url) {
        this.$router.push('/calculate')
      }
    },
    goToAddress (url) {
      if (this.$route.path !== url) {
        this.$router.push('/branches')
      }
    }
  }
}

</script>
<style>

.service_area{
    text-align: -webkit-center;
}
.first_service_item{
  margin-left: 18%;
}
.service_title_zone{
    width: 100% !important;
    color: #5a5a5a;
}
.service_title_zone hr{
    background-color: #3c97f8;
}
  .service_item {
    cursor: pointer;
    margin-top: 20px;
  }

  .service_item i {
    font-size: 30px;
    color: #007AFF;
  }

  .service_item .el-card__body {
    text-align: center;
    align-items: center;
    min-height: 80px;
    padding: 60px;
    border: 2px solid rgb(240 240 240);
  }

  .service_item_desc {
    font-family: 'Geologica', sans-serif;
    font-weight: bold;
  }
</style>
