<template>
  <div class="map_layout">
    <el-row>
      <el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="7" class="text_size">
      <el-card>
        <h2>Салбарын жагсаалт</h2>
        <div class="branch_detail m-t-30 text-align">
         <span> <b><h4>Бөхийн өргөө салбар</h4></b></span>
         <div class="txtleft">
          <i class="fa-solid fa-clock"></i>&nbsp;
          <b>Цагийн хуваарь:</b><br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Даваа, Лхагва, Пүрэв: 11:00-18:00<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Бямба, Ням: 10:00-18:00<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мягмар: Амарна<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Баасан: Дотоод ажилтай<br>
         </div>
        <div class="txtleft">
          <i class="fa-solid fa-map-marker"></i>&nbsp;
          <b>Дэлгэрэнгүй хаяг:</b><br>
          <span class="no-wrap">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>Бөхийн өргөөний баруун урд, Баянзүрх захын зүүн талд Эрхэт худалдааны төв 2 давхарт 2-17,18 тоот</p>
          </span>
        </div>
        <div class="txtleft">
          <i class="fa-solid fa-phone"></i>
          <b> Утас: </b>
          <p>
            <a href="tel:+97672721280" class="text_color_white">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+976 7272 1280
            </a>
          </p>
        </div>
        </div>
        <div class="branch_detail m-t-50 text-align">
         <span> <b><h2>Офицер салбар</h2></b></span>
         <div class="txtleft">
          <i class="fa-solid fa-clock"></i>&nbsp;
          <b>Цагийн хуваарь:</b><br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Даваа, Лхагва, Пүрэв, Бямба: 12:00-17:00<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мягмар, Ням: Амарна<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Баасан: Дотоод ажилтай<br>
         </div>
        <div class="txtleft">
          <i class="fa-solid fa-map-marker"></i>&nbsp;
          <b>Дэлгэрэнгүй хаяг:</b><br>
          <span class="no-wrap">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>Баянзүрх дүүргийн автобусны буудлаар урагшаа эргээд шинэ замаар 500м орчим яваад замын зүүн талд Баянзүрх хороолол А блок 108 байр 1 давхарт зүүн урд хаалга</p>
          </span>
        </div>
        <div class="txtleft">
          <i class="fa-solid fa-phone"></i>
          <b> Утас: </b>
          <p>
            <a href="tel:+97672721280" class="text_color_white">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+976 7272-1280
            </a>
          </p>
        </div>
        </div>
      </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="14" :lg="16" :xl="17">
        <div id="map" style="width: 100%; height: 900px;">
          <GmapMap :center="{ lat: 47.9168493, lng: 106.9341531 }" :zoom="12" style="width: 100%; height: 100%">
            <!-- Бөхийн Өргөө -->
            <GmapMarker :position="{ lat: 47.9168493, lng: 106.9341531 }" :clickable="true" :draggable="true" @click="toggleInfo('buhiinUrguu')"/>
            <GmapInfoWindow v-if="showInfo.buhiinUrguu" :position="{ lat: 47.9168493, lng: 106.9341531 }" @closeclick="closeInfo('buhiinUrguu')">
              <div>
                <h3>Бөхийн өргөө</h3><br>
                <p>Энэ бол Бөхийн өргөө салбарын байршил.</p><br>
                <el-button @click="openGoogleMapBuhiinUrguu">Google Maps руу очих</el-button>
              </div>
            </GmapInfoWindow>
            <!-- Офицеруудын ордон -->
            <GmapMarker :position="{ lat: 47.9133325, lng: 106.9644141 }" :clickable="true" :draggable="true" @click="toggleInfo('officer')"/>
            <GmapInfoWindow v-if="showInfo.officer" :position="{ lat: 47.9133325, lng: 106.9644141 }" @closeclick="closeInfo('officer')">
              <div>
                <h3>Офицеруудын ордон</h3><br>
                <p>Энэ бол Офицеруудын ордон салбарын байршил.</p><br>
                <el-button @click="openGoogleMapOfficer">Google Maps руу очих</el-button>
              </div>
            </GmapInfoWindow>
          </GmapMap>
        </div>
      </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  data () {
    return {
      showInfo: {
        buhiinUrguu: false,
        officer: false
      }
    }
  },
  methods: {
    toggleInfo (location) {
      // Тухайн байршлын InfoWindow-г нээх эсвэл хаах
      this.showInfo[location] = !this.showInfo[location]
    },
    closeInfo (location) {
      // InfoWindow-г хаах
      this.showInfo[location] = false
    },
    openGoogleMapBuhiinUrguu () {
      const url = 'https://www.google.com/maps?q=47.9168493,106.9341531'
      window.open(url, '_blank')
    },
    openGoogleMapOfficer () {
      const url = 'https://www.google.com/maps?q=47.9133325,106.9644141'
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 400px;
}
.map_layout{
  border-top: solid #007AFF;
  text-align: center;
}
.branch_detail{
  background-color: #007AFF;
  color: white;
  border-radius: 10px;
  padding: 18px;
  line-height: 2;
}
.branch_button{
  color: white;
}
@media all and (min-width: 991px) {
  .text_size {
    font-size: 14px;
  }
}
@media all and (max-width: 991px) {
  .text_size{
    font-size: 12px;
  }
}
</style>
