<template>
  <div class="join_area">
    <h4>Хаяг холбох заавар</h4>
      <el-radio-group v-model="joinForm.branch" class="join_branch_option">
      <el-row style="text-align: -webkit-center;">
        <el-col v-for="item in options" :key="item.value" :xs="24" :sm="24" :md="5" :lg="5" :xl="5" class="m-b-10 m-l-20">
          <el-radio :label="item.value" class="custom-radio">
            <img :src="item.image" style="width: 150px; height: 150px;" alt="radio-img" class="radio-image" /> <br />
            <span class="radio-label">{{ item.label }}</span>
          </el-radio>
        </el-col>
      </el-row>
    </el-radio-group>
    <el-row>
      <el-col>
        <span class="p-t-10">Танд шаардлагатай мэдээлэл</span>
      </el-col>
      <el-col>
        <div class="join_info_area">
          <div class="join_info">
            <el-row class="">
              <el-col :span=12 class="m-t-12">Салбарын нэр:</el-col>
              <el-col :span=12>
                <span v-if="joinForm.branch === 'oficer'">天空8792</span>
                <span v-if="joinForm.branch === 'bohiinurguu'">轻客尔1280</span>
                <span v-if="joinForm.branch === 'officerExpress'">天空快运8800</span>
                <span v-if="joinForm.branch === 'buhiinUrguuExpress'">清空快运9881</span>
                <el-button @click="copyToClipboardBranch(koinForm.branch === 'officer' ? '天空8792' : joinForm.branch === 'bohiinurguu' ? '轻客尔1280' : joinForm.branch === 'officerExpress' ? '天空快运8800' : '清空快运9881')"
                  type="text" :style="{ color: isCopiedBranch ? 'green' : 'black' }" :icon="isCopiedBranch ? 'el-icon-check' : 'el-icon-copy-document'" circle>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div class="join_info">
            <el-row class="">
              <el-col :span=12 class="m-t-12">Утасны дугаар (+86):</el-col>
              <el-col :span=12>
                <span v-if="joinForm.branch === 'oficer'">13474735577</span>
                <span v-if="joinForm.branch === 'bohiinurguu'">13614793833</span>
                <span v-if="joinForm.branch === 'officerExpress'">13614793833</span>
                <span v-if="joinForm.branch === 'buhiinUrguuExpress'">13614793833</span>
                <el-button @click="copyToClipboardPhone(joinForm.branch === 'oficer' ? '13474735577' : '13614793833')" type="text" :style="{ color: isCopiedPhone ? 'green' : 'black' }" :icon="isCopiedPhone === true ? 'el-icon-check' : 'el-icon-copy-document'" circle>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div class="join_info">
            <el-row class="">
              <el-col :span=12 class="m-t-12">Муж (Өвөр монголын өөртөө засах орон):</el-col>
              <el-col :span=12>
                <span v-if="joinForm.branch === 'oficer'">内蒙古自治区</span>
                <span v-if="joinForm.branch === 'bohiinurguu'">内蒙古自治区</span>
                <span v-if="joinForm.branch === 'officerExpress'">内蒙古自治区</span>
                <span v-if="joinForm.branch === 'buhiinUrguuExpress'">内蒙古自治区</span>
                <el-button @click="copyToClipboardMuj(joinForm.branch === 'oficer' ? '内蒙古自治区' : '内蒙古自治区')" type="text" :style="{ color: isCopiedMuj ? 'green' : 'black' }" :icon="isCopiedMuj === true ? 'el-icon-check' : 'el-icon-copy-document'" circle>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div class="join_info">
            <el-row class="">
              <el-col :span=12 class="m-t-12">Лиг (Шилийн гол лиг):</el-col>
              <el-col :span=12>
                <span v-if="joinForm.branch === 'oficer'">錫林郭勒盟</span>
                <span v-if="joinForm.branch === 'bohiinurguu'">錫林郭勒盟</span>
                <span v-if="joinForm.branch === 'officerExpress'">錫林郭勒盟</span>
                <span v-if="joinForm.branch === 'buhiinUrguuExpress'">錫林郭勒盟</span>
                <el-button @click="copyToClipboardLig(joinForm.branch === 'oficer' ? '錫林郭勒盟' : '錫林郭勒盟')" type="text" :style="{ color: isCopiedLig ? 'green' : 'black' }" :icon="isCopiedLig === true ? 'el-icon-check' : 'el-icon-copy-document'" circle>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div class="join_info">
            <el-row class="">
              <el-col :span=12 class="m-t-12">Эрэнхот хот:</el-col>
              <el-col :span=12>
                <span v-if="joinForm.branch === 'oficer'">二连浩特市</span>
                <span v-if="joinForm.branch === 'bohiinurguu'">二连浩特市</span>
                <span v-if="joinForm.branch === 'officerExpress'">二连浩特市</span>
                <span v-if="joinForm.branch === 'buhiinUrguuExpress'">二连浩特市</span>
                <el-button @click="copyToClipboardEreen(joinForm.branch === 'oficer' ? '二连浩特市' : '二连浩特市')" type="text" :style="{ color: isCopiedEreen ? 'green' : 'black' }" :icon="isCopiedEreen === true ? 'el-icon-check' : 'el-icon-copy-document'" circle>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div class="join_info">
            <el-row class="">
              <el-col :span=12 class="m-t-12" v-if="joinForm.branch === 'oficer'">Эрэнхот ХЭЗТХАБ</el-col>
              <el-col :span=12 class="m-t-12" v-if="joinForm.branch === 'bohiinurguu'">Эрэнхот нөхөрлөлийн барилгын захиргаа:</el-col>
              <el-col :span=12 class="m-t-12" v-if="joinForm.branch === 'officerExpress'">Эрэнхот нөхөрлөлийн барилгын захиргаа:</el-col>
              \<el-col :span=12 class="m-t-12" v-if="joinForm.branch === 'buhiinUrguuExpress'">Эрэнхот нөхөрлөлийн барилгын захиргаа:</el-col>
              <el-col :span=12>
                <span v-if="joinForm.branch === 'oficer'">二连边境经济技术合作区</span>
                <span v-if="joinForm.branch === 'bohiinurguu'">二连浩特市社区建设管理局</span>
                <span v-if="joinForm.branch === 'officerExpress'">二连浩特市社区建设管理局</span>
                <span v-if="joinForm.branch === 'buhiinUrguuExpress'">二连浩特市社区建设管理局</span>
                <el-button @click="copyToClipboardEreenTo(joinForm.branch === 'oficer' ? '二连边境经济技术合作区' : '二连浩特市社区建设管理局')" type="text" :style="{ color: isCopiedEreenTo ? 'green' : 'black' }" :icon="isCopiedEreenTo === true ? 'el-icon-check' : 'el-icon-copy-document'" circle>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div class="join_info">
            <el-row class="">
              <el-col :span=12 class="m-t-12">Дэлгэрэнгүй хаяг:</el-col>
              <el-col :span=12>
                <span v-if="joinForm.branch === 'oficer'">国门金街北区6号商铺爱蒙货代8792tenger cargo (өөрийн нэр утасны дугаарыг нөхөж бичнэ)</span>
                <span v-if="joinForm.branch === 'bohiinurguu'">玫瑰小区07002号轻客尔1280tenger cargo (өөрийн нэр утасны дугаарыг бичнэ)</span>
                <span v-if="joinForm.branch === 'officerExpress'">玫瑰小区07002号天空快运8800ofitser express (өөрийн нэр утасны дугаарыг бичнэ)</span>
                <span v-if="joinForm.branch === 'buhiinUrguuExpress'">玫瑰小区07002号清空快运9881buhiin urguu express (өөрийн нэр утасны дугаарыг бичнэ)</span>
                <el-button @click="copyToClipboardDetail(joinForm.branch === 'oficer' ? '国门金街北区6号商铺爱蒙货代8792tenger cargo (өөрийн нэр утасны дугаарыг нөхөж бичнэ)': joinForm.branch === 'bohiinurguu' ? '玫瑰小区07002号轻客尔1280tenger cargo (өөрийн нэр утасны дугаарыг бичнэ)' : joinForm.branch === 'officerExpress' ? '玫瑰小区07002号天空快运8800ofitser express (өөрийн нэр утасны дугаарыг бичнэ)' : '玫瑰小区07002号清空快运9881buhiin urguu express (өөрийн нэр утасны дугаарыг бичнэ)')" type="text" :style="{ color: isCopiedDetail ? 'green' : 'black' }" :icon="isCopiedDetail === true ? 'el-icon-check' : 'el-icon-copy-document'" circle>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <el-button class="success_copy" @click="copyAllAddresses">Бүх хаяг хуулах</el-button><br/>
        </div>
      </el-col>
    </el-row>
    <div class="m-t-40" style="text-align: -webkit-center;">
      <span class="join_subtitle">Та ямар худалдааны флатформ дээр хаяг холбох гэж байгаа вэ?</span>
          <el-radio-group v-model="joinFormSite.site" class="join_branch_option m-t-50">
            <el-row gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="item in optionsSite" :key="item.value" class="">
                <el-radio :label="item.value" class="custom-radio m-t-10">
                  <img :src="item.image" style="width: 80px; height: 80px;" alt="radio-img" class="radio-image" /><br/>
                  <span class="radio-label">{{ item.label }}</span>
                </el-radio>
              </el-col>
            </el-row>
          </el-radio-group>
    </div>
    <div>
    <!-- Taobao -->
      <div class="m-t-40" v-if="joinFormSite.site === 'Taobao'">
        <el-row class="join_step_image line-heith-2">
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4" class="p-b-30">
            <span><b>Taobao алхам 1</b></span><br>
            Me хэсэг рүү ороод Баруун дээд буланд байгаа settings хэсэг рүү орно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step1.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step1.jpg']"></el-image><br>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 2</b></span><br>
            Дараа нь хамгийн дээд талын ханзыг сонгон орно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step2.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step2.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 3</b></span><br>
            Хаяг холбох дээрээ дарна<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step3.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step3.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 4</b></span><br>
            Эрээн дээр очих хаягаа тохируулна<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step4.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step4.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 5</b></span><br>
            Эхний ханз буюу улаанаар хүрээлсэн бичгийг сонгоно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step5.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step5.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 6</b></span><br>
            N үсэг дээрх хамгийн эхний ханзыг сонгоно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step6.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step6.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 7</b></span><br>
            X үсэг дээрх ханзыг сонгоно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step7.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step7.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 8</b></span><br>
            Дараа нь E үсэг дээрх ханзыг мөн адил сонгоно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step8.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step8.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 9</b></span><br>
            Хамгийн сүүлд E үсэг дээрх хоёр дахь ханзыг сонгоно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step9.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step9.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Taobao алхам 10</b></span><br>
            Зөв холбосон бол энэ дарааллын дагуу хаяг нь ингэж харагдана. Баруун дээд буланд байгаа дугуйг заавал чеклээрэй<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step10.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/step10.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Анхууруулга!!!</b></span><br>
            ❗️Анхааруулга ❗️Заавал өөрийн нэр, утасны дугаар гэсэн хэсэг дээр өөрийн нэр утасны дугаараа бичээрэй. <br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/warning.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Taobao/warning.jpg']"></el-image>
          </el-col>
        </el-row>
      </div>
      <!-- Pinduoduo -->
      <div class="m-t-40" v-if="joinFormSite.site === 'Pinduoduo'">
        <el-row class="join_step_image line-heith-2">
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Pinduoduo алхам 1</b></span><br/>
            Me хэсэг буюу өөрийн бүртгэл хэсэг рүүгээ орно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step1.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step1.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Pinduoduo алхам 2</b></span><br/>
            Өөрийн бүртгэл хэсэг рүүгээ орсны дараа улаан хүрээлсэн хэсэг рүү орно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step2.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step2.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Pinduoduo алхам 3</b></span><br/>
            Орсны дараагаар мөн улаанаар хүрээлсэн ханзыг дарна<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step3.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step3.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Pinduoduo алхам 4</b></span><br/>
            Постон дээрх хаягыг эхлээд copy-доорой тэхээр шууд хаяг автоматаар гарж ирнэ<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step4.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step4.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Pinduoduo алхам 5</b></span><br/>
            Таны бүртгэл ингэж харагдана заавал өөрийн нэр утасны дугаараа бичээрэй<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step5.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step5.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Pinduoduo алхам 6</b></span><br/>
            Зөв хийсэн бол ингэж харагданаа хаягаа маш сайн шалгаж, нэр, утасны дугаараа заавал бичээрэй<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step6.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Pinduoduo/step6.jpg']"></el-image>
          </el-col>
        </el-row>
      </div>
      <!-- Poizon -->
      <div class="m-t-40" v-if="joinFormSite.site === 'Poizon'">
        <el-row class="join_step_image line-heith-2">
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 1</b></span><br/>
            Баруун доод буланд байгаа өөрийн бүртгэл хэсэг рүүгээ орно<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step1.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step1.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 2</b></span><br/>
            Баруун дээд буланд байгаа хэсэг рүү орно. Зарим хүмүүст энэ хэсэг байхгүй бол settings байгаа тэрлүүгээ ороод дээрээсээ 4дэх ханзыг сонгоно<br/>
            <el-image style="width: 200px; height: 400px"
              :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step2.jpg'"
              :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step2.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 3</b></span><br/>
            Location тэмдгийг сонгоод<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step3.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step3.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 4</b></span><br/>
            Ийм зураг гарч ирнэ. Улаанаар хүрээлсэн байгаа бичгийг дарна.<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step4.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step4.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 5</b></span><br/>
            Эхний 2 мөрөө бөглөсний дараа хотоо сонгох ийм хэсэг гарж ирнэ. Улаанаар хүрээлсэн ханзыг сонгоно.<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step5.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step5.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 6</b></span><br/>
            Мөн адил улаанаар хүрээлсэн ханзыг сонгоно.<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step6.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step6.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 7</b></span><br/>
            Дээрээсээ 5 дах мөрийг сонгоно.<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step7.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step7.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 8</b></span><br/>
            Дээрээсээ 3 дах мөрийг сонгоно.<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step8.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step8.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 9</b></span><br/>
            Дарааллаа зөв хийсэн бол мөрүүд нь ингэж харагдана.<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step9.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step9.jpg']"></el-image>
          </el-col>
          <el-col :xs="22" :sm="12" :md="8" :lg="4" :xl="4">
            <span><b>Poizon алхам 10</b></span><br/>
            Зөв бол ингэж харагдана. Өөрийн нэр, утасны дугаарыг заавал бичнэ үү.<br>
            <el-image style="width: 200px; height: 400px"
            :src="'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step10.jpg'"
            :preview-src-list="['https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com/Poizon/step10.jpg']"></el-image>
          </el-col>
        </el-row>
      </div>
      <!-- 1688 -->
      <div class="m-t-40" v-if="joinFormSite.site === '1688'">
        <el-row class="join_step_image">
          <el-col :span="4">
            <span><b>Taobao алхам 1</b></span><br/>
            Me хэсэг рүү ороод Баруун дээд буланд байгаа settings хэсэг рүү орно<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 2</b></span><br/>
            Дараа нь хамгийн дээд талын ханзыг сонгон орно<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 3</b></span><br/>
            Хаяг холбох дээрээ дарна<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 4</b></span><br/>
            Эрээн дээр очих хаягаа тохируулна<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 5</b></span><br/>
            Эхний ханз буюу улаанаар хүрээлсэн бичгийг сонгоно<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 6</b></span><br/>
            N үсэг дээрх хамгийн эхний ханзыг сонгоно<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 7</b></span><br/>
            X үсэг дээрх ханзыг сонгоно<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 8</b></span><br/>
            Дараа нь E үсэг дээрх ханзыг мөн адил сонгоно<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 9</b></span><br/>
            Хамгийн сүүлд E үсэг дээрх хоёр дахь ханзыг сонгоно<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Taobao алхам 10</b></span><br/>
            Зөв холбосон бол энэ дарааллын дагуу хаяг нь ингэж харагдана. Баруун дээд буланд байгаа дугуйг заавал чеклээрэй<br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
          <el-col :span="4">
            <span><b>Анхууруулга!!!</b></span><br/>
            ❗️Анхааруулга ❗️Заавал өөрийн нэр, утасны дугаар гэсэн хэсэг дээр өөрийн нэр утасны дугаараа бичээрэй. <br>
            <el-image style="width: 200px; height: 400px" src="" :fit="fit"></el-image>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>

</template>

<script>
import buhiinUrguu from '@/assets/images/buhiin_urguu.jpg'
import officer from '@/assets/images/officer.jpg'

export default {
  name: 'JoinComponent',
  data () {
    return {
      row1: 'sex',
      isCopiedPhone: false,
      isCopiedBranch: false,
      isCopiedMuj: false,
      isCopiedLig: false,
      isCopiedEreen: false,
      isCopiedEreenTo: false,
      isCopiedDetail: false,
      joinForm: {
        branch: 'oficer'
      },
      joinFormSite: {
        site: 'Taobao'
      },
      calculateForm: {
        size: '',
        type: '',
        mass: 1,
        urt: null,
        urgun: null,
        undur: null,
        delivery: false,
        resource: '',
        desc: ''
      },
      options: [{
        value: 'oficer',
        label: 'Офицер салбар',
        image: officer
      },
      {
        value: 'bohiinurguu',
        label: 'Бөхийн өргөө салбар',
        image: buhiinUrguu
      },
      {
        value: 'officerExpress',
        label: 'Экспресс - Офицер',
        image: officer
      },
      {
        value: 'buhiinUrguuExpress',
        label: 'Экспресс - Бөхийн өргөө',
        image: buhiinUrguu
      }
      ],
      optionsSite: [
        {
          value: 'Taobao',
          label: 'Taobao',
          image: 'https://no-more-fairytales.com/wp-content/uploads/2024/06/taobao-logo.png'
        }, {
          value: 'Pinduoduo',
          label: 'Pinduoduo',
          image: 'https://cdn6.aptoide.com/imgs/8/8/7/8877278d2624a2e85335412e4376f22a_icon.png'
        },
        {
          value: 'Poizon',
          label: 'Poizon',
          image: 'https://play-lh.googleusercontent.com/f-69Sd00m_TV1Gu859eSMYFqKBl-Nf3nIMu_wHuvZt9ZV8JB8yyi8yx_qtQAAbegBFEb'
        },
        {
          value: '1688',
          label: '1688',
          image: 'https://cdn-1.webcatalog.io/catalog/1688-com/1688-com-icon-filled-256.png?v=1714773084371'
        }
      ],
      activeConfigList: {}
    }
  },
  created () {
    this.getActiveConfig()
  },
  methods: {
    async copyAllAddresses () {
      try {
        const allText = `
        Салбарын нэр: ${this.joinForm.branch === 'oficer' ? '天空8792' : '轻客尔1280'}
        Утасны дугаар (+86): ${this.joinForm.branch === 'oficer' ? '13474735577' : '13614793833'}
        Муж: 内蒙古自治区
        Лиг: 錫林郭勒盟
        Эрэнхот хот: 二连浩特市
        ${this.joinForm.branch === 'oficer' ? 'Эрэнхот ХЭЗТХАБ: 二连边境经济技术合作区' : 'Эрэнхот нөхөрлөлийн барилгын захиргаа: 二连浩特市社区建设管理局'}
        Дэлгэрэнгүй хаяг: ${this.joinForm.branch === 'oficer' ? '国门金街北区6号商铺爱蒙货代8792tenger cargo (өөрийн нэр утасны дугаарыг нөхөж бичнэ)' : '玫瑰小区07002号轻客尔1280tenger cargo (өөрийн нэр утасны дугаарыг бичнэ)'}
      `
        await navigator.clipboard.writeText(allText.trim())
        this.isCopiedBranch = this.isCopiedPhone = this.isCopiedMuj = this.isCopiedLig = this.isCopiedEreen = this.isCopiedEreenTo = this.isCopiedDetail = true
        setTimeout(() => {
          this.isCopiedBranch = this.isCopiedPhone = this.isCopiedMuj = this.isCopiedLig = this.isCopiedEreen = this.isCopiedEreenTo = this.isCopiedDetail = false
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    async copyToClipboardBranch (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.isCopiedBranch = true
        setTimeout(() => {
          this.isCopiedBranch = false
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    async copyToClipboardPhone (text) {
      try {
        await navigator.clipboard.writeText(text) // Текстийг хуулна
        this.isCopiedPhone = true // Copy хийгдсэн төлөвийг өөрчлөх
        setTimeout(() => {
          this.isCopiedPhone = false // 2 секундын дараа буцаах
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    async copyToClipboardMuj (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.isCopiedMuj = true
        setTimeout(() => {
          this.isCopiedMuj = false
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    async copyToClipboardLig (text) {
      try {
        await navigator.clipboard.writeText(text) // Текстийг хуулна
        this.isCopiedLig = true
        setTimeout(() => {
          this.isCopiedLig = false
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    async copyToClipboardEreen (text) {
      try {
        await navigator.clipboard.writeText(text) // Текстийг хуулна
        this.isCopiedEreen = true
        setTimeout(() => {
          this.isCopiedEreen = false
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    async copyToClipboardEreenTo (text) {
      try {
        await navigator.clipboard.writeText(text) // Текстийг хуулна
        this.isCopiedEreenTo = true
        setTimeout(() => {
          this.isCopiedEreenTo = false
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    },
    async copyToClipboardDetail (text) {
      try {
        await navigator.clipboard.writeText(text) // Текстийг хуулна
        this.isCopiedDetail = true
        setTimeout(() => {
          this.isCopiedDetail = false
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    }
  }
}

</script>
<style>
  .join_area {
    border-top: solid #007AFF;
    text-align: center;
    padding-top: 40px !important;
  }
  .success_copy{
    background-color: #007AFF !important;
    color: white !important;
    padding: 30px !important;
    width: 400px !important;
    margin-top: 10px !important;
  }

  .join_info {
    background-color: #D9D9D9;
    width: 400px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
  }
  .join_info_area {
    /* text-align: center; */
    justify-items: center;
  }

  .join_branch_option {
    margin-top: 20px;
    justify-content: center;
  }

  .first_service_item {
    margin-left: 18%;
  }

  .service_title_zone {
    width: 30%;
    color: #5a5a5a;
  }

  .service_title_zone hr {
    background-color: #3c97f8;
  }

  .service_item {
    cursor: pointer;
    margin-top: 20px;
  }

  .service_item i {
    font-size: 30px;
    color: #007AFF;
  }

  .service_item .el-card__body {
    text-align: center;
    align-items: center;
    min-height: 80px;
    padding: 60px;
    border: 2px solid rgb(240 240 240);
  }

  .service_item_desc {
    font-family: 'Geologica', sans-serif;
    font-weight: bold;
  }

  .join_step_image{
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .line-heith-2{
    line-height: 2;
  }
</style>
