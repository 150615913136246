<template>
  <el-header class="app-header">
    <div class="container">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="4" class="logo" :offset="offsetResponsive()">
          <router-link to="/">
            <img src="@/assets/tenger_logo.png" alt="Logo" />
          </router-link>
        </el-col>
        <el-col :span="14" class="navigation">
          <el-menu
            class="el-menu-demo"
            mode="horizontal"
            @select="handleMenuSelect"
          >
            <el-menu-item index="1" @click="goToHome('/')">Нүүр</el-menu-item>
            <el-menu-item index="2" @click="goToCalculate('/calculate')">Үнэ тооцоолох</el-menu-item>
            <el-menu-item index="3">
              <p class="link" @click="goToLink()">
                Сургалтууд
              </p>
            </el-menu-item>
            <el-menu-item index="4" @click="goToAddress('/branches')">Хаяг байршил</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="4" class="navigation tflr">
          <el-button v-if="this.idTokenGetter == null" type="text" class="login_button" @click="login()">Нэвтрэх</el-button>
          <span class="m-l-10 m-r-10">|</span>
          <el-button v-if="this.idTokenGetter == null" class="register_button" @click="register()">Бүртгүүлэх</el-button>
          <router-link to="/profile">
            <el-button v-if="idTokenGetter" class="register_button" icon="el-icon-user-solid">{{this.userDetailGetter.phone1}}</el-button>
          </router-link>
        </el-col>
        <el-col :span="4" class="tflr">
          <div class="mobile-menu" @click="openDrawerMenu()">
            <i class="el-icon-menu"></i>
          </div>
        </el-col>
        <el-drawer title="Меню" :visible.sync="drawerMenuStatus" direction="rtl" :size="350"
          :before-close="handleCloseDrawer">
          <div>
            <el-menu  mode="horizontal">
              <el-row>
                <el-col>
                  <el-menu-item v-if="this.idTokenGetter != null" @click="goToProfile('/profile')">&nbsp;&nbsp;<i class="fas fa-user"></i>&nbsp;&nbsp; Профайл</el-menu-item>
                  <router-link to="/">
                    <el-menu-item>&nbsp;&nbsp;<i class="fas fa-home"></i>&nbsp;&nbsp;Нүүр</el-menu-item>
                  </router-link>
                  <router-link to="/calculate">
                    <el-menu-item>&nbsp;&nbsp;<i class="fas fa-calculator"></i>&nbsp;&nbsp;Үнэ тооцоолох</el-menu-item>
                  </router-link>
                  <div>
                    <el-menu-item>&nbsp;&nbsp;<i class="fas fa-chalkboard-teacher"></i><a @click="goToLink()">&nbsp;&nbsp;Сургалтууд</a></el-menu-item>
                  </div>
                  <router-link to="/branches">
                    <el-menu-item>&nbsp;&nbsp;<i class="fas fa-map-marker-alt"></i> &nbsp;&nbsp;Хаяг байршил</el-menu-item>
                  </router-link>
                  <el-menu-item v-if="this.idTokenGetter == null" @click="login()">&nbsp;&nbsp;<i class="fas fa-sign-in-alt"></i>&nbsp;&nbsp; Нэвтрэх</el-menu-item>
                  <el-menu-item v-if="this.idTokenGetter == null" @click="register()">&nbsp;&nbsp;<i class="fas fa-user-plus"></i>&nbsp;&nbsp; Бүртгүүлэх</el-menu-item>
                  <el-menu-item v-if="this.idTokenGetter != null" @click="logout()">&nbsp;&nbsp;<i class="fas fa-sign-out-alt"></i>&nbsp;&nbsp; Гарах</el-menu-item>
                </el-col>
              </el-row>
            </el-menu>
          </div>
        </el-drawer>
      </el-row>
      <LoginDialog v-if="true"></LoginDialog>
      <RegisterDialog v-if="this.$root.registerDialog"/>
    </div>
  </el-header>
</template>

<script>
import RegisterDialog from '@/components/Dialog/registerDialog.vue'
import LoginDialog from './Dialog/loginDialog.vue'
import {
  mapGetters
} from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'idTokenGetter',
      'userDetailGetter'
    ])
  },
  components: {
    LoginDialog,
    RegisterDialog
  },
  name: 'HeaderComponent',
  data () {
    return {
      activeMenu: null,
      isMobileMenuVisible: false,
      windowWidth: window.innerWidth,
      drawerMenuStatus: false
    }
  },
  methods: {
    offsetResponsive () {
      if (this.windowWidth > 900) {
        return 3
      } else {
        return 0
      }
    },
    handleMenuSelect (index) {
      this.activeMenu = index
      console.log(`Menu item ${index} selected`)
    },
    toggleMobileMenu () {
      this.isMobileMenuVisible = !this.isMobileMenuVisible
    },
    login () {
      this.$root.loginDialog = true
    },
    register () {
      this.$root.registerDialog = true
    },
    handleCloseDrawer () {
      this.drawerMenuStatus = false
    },
    openDrawerMenu () {
      this.drawerMenuStatus = true
    },
    goToHome (url) {
      if (this.$route.path !== url) {
        this.$router.push('/')
      }
    },
    goToCalculate (url) {
      if (this.$route.path !== url) {
        this.$router.push('/calculate')
      }
    },
    goToAddress (url) {
      if (this.$route.path !== url) {
        this.$router.push('/branches')
      }
    },
    goToLink () {
      window.open('https://www.facebook.com/profile.php?id=100070131997091', '_blank')
    },
    logout () {
      localStorage.clear()
      location.reload()
    },
    goToProfile (url) {
      if (this.$route.path !== url) {
        this.$router.push('/profile')
      }
    }
  }
}
</script>

  <style lang="scss" scoped>

  /* Transition effect */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
