<template>
  <div>
        <router-view />
    <loader-item :isLoader='this.$root.fullscreenLoading'></loader-item>
    <resetPassword v-if="this.$root.resetPasswordDialog"></resetPassword>
  </div>
</template>
<script>
import LoaderItem from '@/components/loader/loader-item.vue'
import resetPassword from '@/components/OTP/resetPassword.vue'

export default {
  name: 'MainLayout',
  components: {
    LoaderItem,
    resetPassword
  },
  data () {
    return {
      containerCollapsed: 'toggled',
      tabledata: [],
      user: JSON.parse(localStorage.getItem('user'))

    }
  },
  created () {
  },
  methods: {
    showMenu () {
      this.$root.showMenuDrawerStatus = true
    },
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    }
  }
}

</script>
<style scoped>
.el-header {
  background-color: #ffffff;
  color: #333;
  line-height: 60px;
  padding: 0px;
  min-height: 68px;
}

.el-aside {
  color: #333;
}

.item {
  margin-top: 12px;
  margin-right: 20px;
}
</style>
