<template>
    <div>
      <el-dialog title="Бүртгүүлэх" :visible.sync="this.$root.registerDialog"  width="400px" :before-close="handleClose" center>
        <el-form class="text_center" :model="registerForm" :rules="rules" >
          <el-form-item>
            <span class="no-wrap content_justify_center">Утасны дугаар<p class="text_red"> *</p></span>
            <el-input class="track_code_area_input" placeholder="Та утасны дугаараа оруулна уу" v-model="registerForm.phone_number"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="no-wrap content_justify_center">Нууц үг<p class="text_red"> *</p></span>
            <el-input class="track_code_area_input" placeholder="Та нууц үгээ оруулна уу" type="password" v-model="registerForm.password" required></el-input>
          </el-form-item>
          <el-form-item>
            <span class="no-wrap content_justify_center">Нууц үг давтах<p class="text_red"> *</p></span>
            <el-input class="track_code_area_input" placeholder="Нууц үгээ дахин оруулна уу" type="password" v-model="registerForm.confirmPassword" prop="confirmPassword" required></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="button_background_color track_code_button" @click="register(registerForm)">
              Бүртгүүлэх
            </el-button>
            <span style="font-size: 12px;"><span class="text_color"><b class="cursor" @click="login()">Нэвтрэх </b></span></span>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
</template>

<script>
import services from '@/helpers/services'

export default {
  name: 'registerDialog',
  data () {
    return {
      registerForm: {
        phone_number: '',
        confirmPassword: '',
        password: '',
        company_id: 1
      },
      rules: {
        password: [{ required: true, message: 'Нууц үгээ оруулна уу', trigger: 'blur' }],
        confirmPassword: [
          { required: true, message: 'Нууц үгээ дахин оруулна уу', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.password) {
                callback(new Error('Нууц үг таарахгүй байна'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleClose () {
      this.$root.registerDialog = false
    },
    register () {
      services.register(this.registerForm.phone_number, this.registerForm.password, this.registerForm.company_id)
        .then((response) => {
          this.$alert('Та ажилттай бүртгүүллээ. Өөрийн эрхээрээ нэвтэрч орно уу..', 'Амжилттай', {
            confirmButtonText: 'Ойлголоо',
            callback: action => {
              // this.$router.push({
              //   path: '/'
              // })
              location.reload()
            }
          })
        })
        .catch((error) => {
          this.$alert('Уучлаарай: ' + error, 'Алдаа', {
            confirmButtonText: 'Ойлголоо'
          })
        })
    },
    login () {
      this.$root.registerDialog = false
      this.$root.loginDialog = true
    }
  }
}
</script>

<style>

</style>
