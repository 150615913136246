<template>
  <div class="calculate_layout">
    <el-row class="m-t-100 m-b-100">
      <el-col :offset="offsetResponsive()" :xs="20" :sm="20" :md="5" :lg="8" :xl="5">
       <b> Урьдчилсан байдлаар үнэ тооцоолох</b><br><br>
        <el-form :model="calculateForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="top" class="demo-ruleForm">
          <!-- <el-form-item label="Ачааны хэмжээ" prop="resource">
            <el-radio-group v-model="calculateForm.product_size">
              <el-radio v-for="item in options" :key="item.value" :label="item.value" class="custom-radio">
                <img :src="item.image" alt="radio-img" class="radio-image" /> <br />
                <span class="radio-label">{{ item.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item> -->
          <!-- <el-form-item label="Ачааны төрөл" prop="resource" v-if="this.calculateForm.product_size== 'small'">
            <el-radio-group v-model="calculateForm.product_type">
              <el-radio v-for="item in option_types" :key="item.value" :label="item.value" class="custom-radio">
                <img :src="item.image" alt="radio-img" class="radio-image" /> <br />
                <span class="radio-label">{{ item.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="Ачааны төрөл сонгоно уу" prop="configTypeId">
            <el-select v-model="configTypeId" placeholder="Сонгоно уу" class="w-100">
              <el-option v-for="item,index in activeConfigList.ConfigType" :key="index" :label="item.name" :value="item.id" @click.native="transactionValue(item.is_ezelhvvn)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Жин" prop="jin">
            <el-input-number class="track_code_area_input" :controls="false" v-model="calculateForm.jin" controls-position="right" :min="0" ></el-input-number>
            <b class="m-l-20" v-if="this.is_ezelhuun === false"> гр</b>
            <b class="m-l-20" v-if="this.is_ezelhuun === true"> кг</b><br/>
            <!-- <span class="desc">Та барааныхаа жинг кг- аар оруулахыг анхаарна уу.</span> -->
          </el-form-item>
          <el-form-item label="Ачааны урт, өргөн, өндөрийг оруулна уу /см/" v-if="this.is_ezelhuun === true">
            <el-row>
              <!-- <span>Урт</span>
              <span class="m-l-90">Өргөн</span>
              <span class="m-l-90">Өндөр</span><br/> -->
              <el-col :span="6">
                Урт
                <span class="no-wrap">
                  <el-input-number class="track_code_area_input" style="width: 90px;" :controls="false" v-model="calculateForm.urt" :min="0"></el-input-number><b>см</b>
                </span>
              </el-col>
              <el-col :span="6" class=" m-l-20">
                Өргөн
                <span class="no-wrap">
                  <el-input-number class="track_code_area_input" style="width: 90px;" :controls="false" v-model="calculateForm.urgun" :min="0" ></el-input-number><b>см</b>
                </span>
              </el-col>
              <el-col :span="6" class=" m-l-20">
                Өндөр
                <span class="no-wrap">
                  <el-input-number class="track_code_area_input" style="width: 90px;" :controls="false" v-model="calculateForm.undur" :min="0" ></el-input-number><b>см</b><br/>
                </span>
              </el-col>
              <!-- <span class="desc">Та барааныхаа харьцааг СМ оруулахыг анхаарна уу.</span> -->
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="track_code_button"  @click="calculatePrice()">Тооцоолох</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col  :offset="2" :xs="20" :sm="20" :md="10" :lg="8" :xl="10">
        <div class="calculate_result">
          <b>Тооцоолсон үнэ</b><br/>
          <div v-if="this.calculateForm.product_size==='big'">Урт:<b>{{ this.calculateForm.urt }}см</b>  Өргөн: <b>{{ this.calculateForm.urgun }}см</b> Өндөр: <b>{{ this.calculateForm.undur }}см</b></div>
          <br/>
          <el-image
            style="width: 100px; height: 100px"
            :src="this.calculateForm.product_type === 'box' ? 'https://cdn-icons-png.flaticon.com/512/2989/2989545.png' : 'https://cdn-icons-png.flaticon.com/512/5337/5337564.png'"
            fit="fill">
          </el-image><br/>
          <h2>Урьдчилсан үнэ: &nbsp;<p class="paymentColor">{{this.price | formatCurrency}}</p></h2><br/>
          <span class="calculate_description line_height1">Энэхүү үнэ эцсийн үнэ биш бөгөөд эрхэм харилцагч та үйлчилгээний амжилттай холбогдоно уу</span>
          </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import services from '@/helpers/services'
import ReduceImage from '../assets/cube.png'

export default {
  props: {},
  mounted () {},
  data () {
    return {
      reduceImageUrl: ReduceImage,
      windowWidth: window.innerWidth,
      calculateForm: {
        product_size: '',
        product_type: '',
        jin: 1,
        urt: null,
        urgun: null,
        undur: null
      },
      rules: {
        configTypeId: [{
          required: true,
          message: 'Ачааны төрөл сонгоно уу',
          trigger: 'blur'
        }
        ],
        jin: [{
          required: true,
          message: 'Та ачааныхаа жингээ оруулна уу',
          trigger: 'blur'
        }]
      },
      price: null,
      configTypeId: null,
      activeConfigList: {},
      is_ezelhuun: false
    }
  },
  created () {
    this.getActiveConfig()
  },
  methods: {
    offsetResponsive () {
      if (this.windowWidth > 900) {
        return 4
      } else {
        return 2
      }
    },
    calculatePrice () {
      if (this.configTypeId === null) {
        this.$alert('Та ачааныхаа төрлийг сонгоно уу.', 'Анхааруулга', {
          confirmButtonText: 'Ачааны төрөл сонгох'
        })
        return
      } else
      if (this.is_ezelhuun === true && this.calculateForm.urt === 0) {
        this.$alert('Та ачааныхаа уртыг оруулна уу.', 'Анхааруулга', {
          confirmButtonText: 'Ачааны урт оруулах'
        })
        return
      } else
      if (this.is_ezelhuun === true && this.calculateForm.undur === 0) {
        this.$alert('Та ачааныхаа өндрийг оруулна уу.', 'Анхааруулга', {
          confirmButtonText: 'Ачааны өндөр оруулах'
        })
        return
      } else
      if (this.is_ezelhuun === true && this.calculateForm.urgun === 0) {
        this.$alert('Та ачааныхаа өргөнийг оруулна уу.', 'Анхааруулга', {
          confirmButtonText: 'Ачааны өргөн оруулах'
        })
        return
      }
      this.$root.fullscreenLoading = true
      services.calculatePrice(this.configTypeId, this.calculateForm.jin, this.calculateForm.urt, this.calculateForm.undur, this.calculateForm.urgun)
        .then((response) => {
          if (response) {
            this.price = response.finalPrice
            this.$root.fullscreenLoading = false
            if (this.price === 0) {
              this.$notify({
                title: 'Анхааруулга',
                message: 'Таны оруулсан ачааны харьцаа сонгосон төрөлд багтахгүй байна.'
              })
            }
          }
        })
    },
    getActiveConfig () {
      services.getActiveConfig()
        .then((response) => {
          this.activeConfigList = response.data
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    },
    transactionValue (data) {
      this.is_ezelhuun = data
    }
  }
}

</script>

<style scoped>
.calculate_description{
  color: gray;
}
.calculate_layout{
  border-top: solid #007AFF;
  line-height: 2.5;
}
  svg {
    max-width: 100%;
  }
.calculate_result{
background-color: #D4E6FA;
padding: 10%;
text-align: center;
line-height: 2.5;
}
</style>
