<template>
  <div>
    <el-dialog title="Нэвтрэх" :visible.sync="this.$root.loginDialog"  width="400px" :before-close="handleClose" center>
      <el-form class="text_center">
          <el-form-item>
            <span class="content_justify_center no-wrap">Бүртгэлтэй утасны дугаар <p class="text_red"> *</p></span>
            <el-input class="track_code_area_input" v-model="loginForm.phone_number" placeholder="Утасны дугаараа оруулна уу"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="content_justify_center no-wrap">Нууц үг<p class="text_red"> *</p></span>
            <el-input class="track_code_area_input" v-model="loginForm.password" type="password" placeholder="Нууц үгээ оруулна уу"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="button_background_color track_code_button" @click="login()">Нэвтрэх</el-button><br>
            <el-button type="text" class="font_format text_color m-t-20" @click="register()">Бүртгүүлэх</el-button><br>
            <el-button type="text" class="font_format text_color m-t-20 text_blue_color" @click="resetPassword()">Нууц үг сэргээх</el-button>
          </el-form-item>
        </el-form>
        <div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services'
export default {
  name: 'loginDialog',
  data () {
    return {
      loginForm: {
        phone_number: '',
        password: ''
      }
    }
  },
  components: {
  },
  methods: {
    handleClose () {
      this.$root.loginDialog = false
    },
    login () {
      services.login(this.loginForm.phone_number, this.loginForm.password)
        .then((response) => {
          if (response.token) {
            this.$store.dispatch('authUserLoginPage', response)
            this.$store.dispatch('userAdd', response.user)
            this.$alert('Амжилттай нэвтэрлээ.', 'Амжилттай', {
              confirmButtonText: 'Ойлголоо',
              callback: action => {
                this.$router.push({
                  path: '/profile'
                })
                location.reload()
              }
            })
            this.$root.loginDialog = false
          }
        })
        .catch((error) => {
          this.$alert('Уучлаарай: ' + error, 'Алдаа', {
            confirmButtonText: 'Ойлголоо'
          })
        })
    },
    register () {
      this.$root.loginDialog = false
      this.$root.registerDialog = true
    },
    resetPassword () {
      this.$root.loginDialog = false
      this.$root.resetPasswordDialog = true
    }
  }
}
</script>

<style>

</style>
