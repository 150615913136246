<template>
    <div>
      <el-dialog :title="addressForm.id ? 'Хаяг засах' : 'Хаяг үүсгэх'"  :visible.sync="this.$root.addressAddDialog"  width="80%" :before-close="handleClose" center>
          <el-form :model="addressForm" >
            <el-form-item>
              <p class="p-r-10 p-b-10">Nick name</p>
              <el-input class="p-b-20" v-model="addressForm.nick_name" placeholder="Гэр, ажил гэх мэт">
              </el-input>
            </el-form-item>
            <el-form-item>
              <p class="p-r-10 p-b-10">Дэлгэрэнгүй хаяг</p>
              <el-input class="p-b-20" v-model="addressForm.detail_address" type="textarea" placeholder="Байр, давхар, тоот, орцны кодоо оруулна уу"></el-input>
            </el-form-item>
            <el-form-item>
              Google map <br>
              <p class="map_text">Та доорх хэсэгт location тэмдгийн улаан заалтыг зөөж хаягийн байрлал дээр тавьна уу.</p><br>
              <div id="map" style="width: 100%; height: 400px;">
                <GmapMap
                  :center="{ lat: addressForm.lat || 47.9162, lng: addressForm.lng || 106.9350834 }"
                  :zoom="12"
                  style="width: 100%; height: 100%"
                >
                  <GmapMarker
                    :position="{ lat: addressForm.lat || 47.9162, lng: addressForm.lng || 106.9350834 }"
                    :clickable="true"
                    :draggable="true"
                    @dragend="updateCoordinates"
                  ></GmapMarker>
                </GmapMap>

              </div>
            </el-form-item>
            <el-form-item>
              <el-button class="button_background_color track_code_button" @click="createAddress()">Хадгалах</el-button><br>
            </el-form-item>
          </el-form>
      </el-dialog>
    </div>
  </template>

<script>
import services from '@/helpers/services'
import {
  mapGetters
} from 'vuex'

export default {
  name: 'addressAddDialog',
  computed: {
    ...mapGetters([
      'userDetailGetter'
    ])
  },
  props: {
    addressSelect: Object
  },
  created () {
    this.reBuildItem()
  },
  data () {
    return {
      addressForm: {
        id: null,
        nick_name: null,
        detail_address: null,
        lng: null,
        lat: null
      }
    }
  },
  methods: {
    reBuildItem () {
      if (this.addressSelect.id) {
        this.addressForm = this.addressSelect
      }
    },
    handleMapClick (event) {
      // Газрын зураг дээр дарж байхад координатуудыг авах
      this.addressForm.lat = event.latLng.lat()
      this.addressForm.lng = event.latLng.lng()
    },
    handleMarkerDragEnd (event) {
      // Маркер чирсэн үед координатыг шинэчилэх
      this.addressForm.lat = event.latLng.lat()
      this.addressForm.lng = event.latLng.lng()
    },
    updateCoordinates (event) {
      this.addressForm.lat = event.latLng.lat()
      this.addressForm.lng = event.latLng.lng()
    },
    handleClose () {
      this.$root.addressAddDialog = false
    },
    createAddress () {
      if (!this.addressForm.lat || !this.addressForm.lng) {
        this.$notify({
          title: 'Анхааруулга',
          message: ('Газрын зураг дээрээс байрлалаа сонгоно уу.'),
          duration: 1500
        })
        return
      } else if (!this.addressForm.nick_name) {
        this.$notify({
          title: 'Анхааруулга',
          message: ('Хаягийн nick name оруулна уу.'),
          duration: 1500
        })
        return
      } else if (!this.addressForm.detail_address) {
        this.$notify({
          title: 'Анхааруулга',
          message: ('Дэлгэрэнгүй хаяг оруулна уу.'),
          duration: 1500
        })
        return
      }
      this.$root.fullscreenLoading = true
      if (this.addressForm.id) {
        services.updateAddress(
          this.addressForm.id,
          this.addressForm.nick_name,
          this.addressForm.detail_address,
          this.addressForm.lng,
          this.addressForm.lat,
          this.userDetailGetter.id)
          .then((response) => {
            if (response) {
              this.$alert('Таны хаяг амжилттай бүртгэгдлээ.', 'Амжилттай', {
                confirmButtonText: 'Ойлголоо',
                callback: action => {
                  location.reload()
                  this.$root.fullscreenLoading = false
                }
              })
              this.$root.addressAddDialog = false
            }
          })
          .catch((error) => {
            console.error('Алдаа:', error.message)
          })
      } else {
        services.createAddress(
          this.addressForm.nick_name,
          this.addressForm.detail_address,
          this.addressForm.lng,
          this.addressForm.lat,
          this.userDetailGetter.id)
          .then((response) => {
            if (response) {
              this.$alert('Таны хаяг амжилттай бүртгэгдлээ.', 'Амжилттай', {
                confirmButtonText: 'Ойлголоо',
                callback: action => {
                  location.reload()
                  this.$root.fullscreenLoading = false
                }
              })
              this.$root.addressAddDialog = false
            }
          })
          .catch((error) => {
            console.error('Алдаа:', error.message)
          })
      }
    }
  }
}
</script>

  <style>
.map_text {
  color: red;
  line-height: 17px;
}
  </style>
