<template>
    <div>
      <div>
        <el-row>
          <el-col>
            <el-table v-if="this.orderListProp" :data="this.orderListProp">
                <el-table-column label="№" type="index"></el-table-column>
                <el-table-column label="Track код" prop="track_code" width="150px"></el-table-column>
                <el-table-column label="Төлөв" width="250">
                    <template slot-scope="scope">
                        {{ returnStatusValue(scope.row.progress_code) }}
                    </template>
                </el-table-column>
                <el-table-column label="Огноо" width="100px">
                    <template slot-scope="scope">{{ scope.row.createdAt.split('T')[0] }}</template>
                </el-table-column>
                <el-table-column label="Үнэ">
                    <template slot-scope="scope">
                        {{ scope.row.price | formatCurrency() }}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="Үйлдэл" width="150px">
                    <template slot-scope="scope">
                    <el-button @click="getTrackCodeByCode(scope.row)" class="cursor button_background_color border_radius_10">Дэлгэрэнгүй</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <div v-else>
                Өгөгдөл байхгүй бхйна
            </div>
        <div>
            <OrderDetailDialog v-if="this.$root.orderDetailDialog" :orderDetailProp="selectOrder" />
        </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </template>

<script>
import services from '@/helpers/services'
import OrderDetailDialog from './orderDetailDialog.vue'
export default {
  data () {
    return {
      selectOrder: {}
    }
  },
  props: {
    trackCodesList: Array,
    orderListProp: Array,
    emptyValue: Object
  },
  components: {
    OrderDetailDialog
  },
  created () {

  },
  methods: {
    returnStatusValue (data) {
      console.log(data)
      const result = this.$root.statusArray.find(element => element.value === data)
      if (result) {
        return result.label
      } else {
        return 'empty'
      }
    },
    async getTrackCodeByCode (data) {
      try {
        this.$root.fullscreenLoading = true
        const response = await services.getTrackCodeByCode(data.track_code)
        if (response.type === 'success') {
          this.selectOrder = response.data
          this.$root.orderDetailDialog = true
        } else {
          this.$alert('Алдаа: Захиалгын мэдээллийг авахад алдаа гарлаа.', 'Алдаа', {
            confirmButtonText: 'Ойлголоо'
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$root.fullscreenLoading = false
      }
    }
  }
}
</script>

  <style>
  .m-b-10 {
    margin-bottom: 10px;
  }
  </style>
