<template>
    <div id="app" class="home_page">
      <HeaderComponent />
        <div class="gap">
          <ProfileComponents/>
        </div>
        <FooterComponents class="m-t-100"/>
    </div>
  </template>
<script>
import ProfileComponents from '@/components/ProfileComponent.vue'
import FooterComponents from '@/components/FooterComponents.vue'
import HeaderComponent from '@/components/HeaderComponent'
export default {
  name: 'HomePage',
  data () {
    return {
    }
  },
  components: {
    HeaderComponent,
    ProfileComponents,
    FooterComponents
  },
  created () {
  },

  methods: {}
}

</script>

<style>
  @media only screen and (min-width: 900px) {
    .gap {
      margin-left: 200px;
    }
  }
  @media only screen and (max-width: 900px) {
    .gap {
      margin-left: 50px;
    }
  }
</style>
